<template>
  <a-drawer width="1000px" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :mask-closable="formType == 2? true : false">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="备注" prop="remark" >-->
<!--        <a-input v-model="form.remark" placeholder="请输入备注" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="用户id关联{zb_user.id}" prop="userId" >-->
<!--        <a-input v-model="form.userId" placeholder="请输入用户id关联{zb_user.id}" />-->
<!--      </a-form-model-item>-->

<!--      <a-form-model-item label="默认：1-开启 0-禁用" prop="status" >-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Voltage Curves Name:" prop="name" >
        <a-input v-model="form.name" placeholder="" :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false" />
      </a-form-model-item>
      <a-form-model-item label="Voltage Curves Description:" prop="description" >
        <a-input v-model="form.description" placeholder="" :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false" />
      </a-form-model-item>
      <a-form-model-item label="Be Available For:" prop="skuId" >
<!--        <a-input v-model="form.skuId" placeholder="" />-->
        <a-select
          placeholder="-Brand Product-"
          style="width: 100%"
          showSearch
          v-model="form.skuId"
          optionFilterProp="title"
          @change="changeBrandProduct"
          :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false"
        >
          <a-select-option v-for="item in productListSku" :key="item.id" :value="item.id" :title="item.name + '('+item.productModel+')'">
            {{ item.name + '('+item.productModel+')' }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Notes:" prop="notes" >
        <a-input v-model="form.notes" placeholder="" :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false" />
      </a-form-model-item>
<!--      <a-form-model-item label="适用于" prop="availableFor" >-->
<!--        <a-input v-model="form.availableFor" placeholder="请输入适用于" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="品牌id" prop="brandId" >-->
<!--        <a-input v-model="form.brandId" placeholder="请输入品牌id" />-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Voltage Curves Qty :" prop="curvesQty" v-if="businessType">
<!--        <a-input v-model="form.curvesQty" placeholder="请输入曲线数" />-->
        <a-select v-model="form.curvesQty" :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false">
          <a-select-option :key="1">1</a-select-option>
          <a-select-option :key="2">2</a-select-option>
          <a-select-option :key="3">3</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Set Voltage Curves:" prop="notes" v-if="curvesListDataArray.length">
        <a-tabs type="card" v-model="typeIndex" @change="changeTabs">
          <a-tab-pane key="0"  v-if="form.curvesQty >= 1" tab="Light">
            <myCustomEcharts id="myCustomEcharts1" ref="echartsOne" :minPower="powerData.minOutputVoltage" :secondPower="powerData.secondPower" :maxPower="powerData.maxOutputVoltage" :width="'935px'" :height="'150px'" :option="curvesListDataArray[0]" @changeCharts="changeCharts($event,0)"></myCustomEcharts>
          </a-tab-pane>
          <a-tab-pane key="1" v-if="form.curvesQty >= 2" tab="Smooth">
            <myCustomEcharts id="myCustomEcharts2" ref="echartsTwo" :width="'100%'" :secondPower="powerData.secondPower" :minPower="powerData.minOutputVoltage" :maxPower="powerData.maxOutputVoltage" :height="'150px'" :option="curvesListDataArray[1]" @changeCharts="changeCharts($event,1)"></myCustomEcharts>
          </a-tab-pane>
          <a-tab-pane key="2" v-if="form.curvesQty >= 3" tab="Strong">
            <myCustomEcharts id="myCustomEcharts3" ref="echartsThree" :width="'100%'" :secondPower="powerData.secondPower" :minPower="powerData.minOutputVoltage" :maxPower="powerData.maxOutputVoltage" :height="'150px'" :option="curvesListDataArray[2]" @changeCharts="changeCharts($event,2)"></myCustomEcharts>
          </a-tab-pane>

        </a-tabs>
        <div style="margin-top: 10px;" v-if="powerData && curvesListDataArray.length">
          The Voltage curve is between {{ powerData.minOutputVoltage }}v-{{ powerData.maxOutputVoltage }}v, after the 8th second, the same as the 8th second.
        </div>
      </a-form-model-item>
      <a-form-model-item label="Status:" prop="status" >
        <a-select v-model="form.status" :disabled="userInfo.roleList[0].roleKey == 'platform' ? true : false">
          <a-select-option :key="1">Enable</a-select-option>
          <a-select-option :key="0">Disable</a-select-option>
        </a-select>
      </a-form-model-item>
<!--      {{userInfo.roleList[0].roleKey}}-->
      <div class="bottom-control" v-if="!(userInfo.roleList[0].roleKey == 'platform')">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getSkuCurves, addSkuCurves, updateSkuCurves } from '@/api/cigarette/skuCurves'
import {detailBrandSkuPower, listByUserId, listSku} from "@/api/cigarette/sku";
// import * as echarts from "echarts";
// import Template from "@/views/sms/template";
import myCustomEcharts from "./myCustomEcharts";
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    // Template
    myCustomEcharts
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      productListSku: [],
      // 表单参数
      form: {
        id: null,

        createTime: null,

        remark: null,

        userId: null,

        skuId: null,

        status: 1,

        name: null,

        description: null,

        notes: null,

        availableFor: null,

        brandId: null,

        curvesQty: 3

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      },
      curvesListDataArray:[],
      typeIndex:"0",
      myChart:[null,null,null],
      powerData: {
        minOutputVoltage:0.4,
        maxOutputVoltage:0.4,
        secondPower: 0
      },
      businessType:false
    }
  },
  filters: {
  },
  created () {
    this.getSkuListData();
  },
  computed: {
    userInfo() {
      return this.$store.getters.info;
    }
  },
  watch: {

  },
  mounted () {
  },
  methods: {
    changeBrandProduct(e) {
      console.log(e);
      this.getPowerData(e);
      let filterData = this.productListSku.filter(i => i.id == e);
      if(filterData.length > 0) {
        console.log(filterData[0].businessType)
        this.businessType = filterData[0].businessType == '421627053432004609' ? true : false;
        this.form.brandId = filterData[0].brandId;
      }
    },
    changeCharts(e,index) {
      this.curvesListDataArray[index].series[0].data = e;
    },
    changeTongjiTu(first,index,option) {
      console.log(this.myChart[first]);
      if(this.myChart[first].getOption()) {
        let chartData = this.myChart[first].getOption().series[0].data;
        if(option == 1) {
          //+
          chartData[index] = chartData[index]+1;
          console.log(chartData)
          this.myChart[first].setOption({
            series:[{
              data: chartData,
            }]
          })
        } else {
          //-
          chartData[index] = chartData[index]-1;
          this.myChart[first].setOption({
            series:[{
              data: chartData,
            }]
          })
        }
      }
    },

    clickChangeType(index) {
      this.typeIndex = index;
    },
    getSkuListData() {
      listByUserId({
        status:1
      }).then(res => {
        this.productListSku = res.data;
      })
    },
    changeTabs() {
      this.$refs['echartsOne'].changePeizhiShuju();
      this.$refs['echartsTwo'].changePeizhiShuju();
      this.$refs['echartsThree'].changePeizhiShuju();
    },
    getPowerData(id,curvesRecordList) {
      detailBrandSkuPower({
        id:id
      }).then(res =>{
        this.powerData = res.data;
        if(this.formType == 1) {
          this.curvesListDataArray = [{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower,
            },
            series: [
              {
                data: [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },
              }
            ]
          },{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S'],
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower
            },
            series: [
              {
                data: [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },

              }
            ]
          },{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower,
              axisLabel: {
                formatter: '{value}w'
              }
            },
            series: [
              {
                data: [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },
              }
            ]
          }];
          if(this.typeIndex === '0' ) {
            this.$refs['echartsOne'].changePeizhiShuju();
          } else if(this.typeIndex == '1') {
            this.$refs['echartsTwo'].changePeizhiShuju();

          } else if(this.typeIndex == '2') {
            this.$refs['echartsThree'].changePeizhiShuju();
          }
          // this.curvesListDataArray[0].yAxis.min = res.data.minPower
          // this.curvesListDataArray[0].yAxis.max = res.data.maxPower
          // this.curvesListDataArray[1].yAxis.min = res.data.minPower
          // this.curvesListDataArray[1].yAxis.max = res.data.maxPower
          // this.curvesListDataArray[2].yAxis.min = res.data.minPower
          // this.curvesListDataArray[2].yAxis.max = res.data.maxPower
          // this.curvesListDataArray[0].series[0].data = [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower]
          // this.curvesListDataArray[1].series[0].data = [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower]
          // this.curvesListDataArray[2].series[0].data = [res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower,res.data.minPower]
        } else {
          let oneCurves =  curvesRecordList.filter(i => i.level === 0);
          let twoCurves =  curvesRecordList.filter(i => i.level === 1);
          let threeCurves =  curvesRecordList.filter(i => i.level === 2);
          this.curvesListDataArray = [{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower
            },
            series: [
              {
                data:oneCurves.length > 0 ? [oneCurves[0].one,oneCurves[0].two,oneCurves[0].three,oneCurves[0].four,oneCurves[0].five,oneCurves[0].six,oneCurves[0].seven,oneCurves[0].eight] : [0,0,0,0,0,0,0,0],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },
              }
            ]
          },{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower
            },
            series: [
              {
                data: twoCurves.length > 0 ? [twoCurves[0].one,twoCurves[0].two,twoCurves[0].three,twoCurves[0].four,twoCurves[0].five,twoCurves[0].six,twoCurves[0].seven,twoCurves[0].eight] : [0,0,0,0,0,0,0,0],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },
              }
            ]
          },{
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['1S', '2S', '3S', '4S', '5S', '6S', '7S','8S']
            },
            grid: {
              top:"20px",
              left:"50px",
              bottom:"20px",
              right:"20px"
            },
            yAxis: {
              type: 'value',
              min:res.data.minPower,
              max:res.data.maxPower
            },
            series: [
              {
                data: threeCurves.length > 0 ? [threeCurves[0].one,threeCurves[0].two,threeCurves[0].three,threeCurves[0].four,threeCurves[0].five,threeCurves[0].six,threeCurves[0].seven,threeCurves[0].eight] : [0,0,0,0,0,0,0,0],
                type: 'line',
                areaStyle: {},
                label: {
                  show: true,
                  position: 'top',
                  formatter: '{c} w'
                },
              }
            ]
          }];
          this.$refs['echartsOne'].changePeizhiShuju();
          this.$refs['echartsTwo'].changePeizhiShuju();
          this.$refs['echartsThree'].changePeizhiShuju();
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        createTime: null,
        remark: null,
        userId: null,
        skuId: null,
        status: 1,
        name: null,
        description: null,
        notes: null,
        availableFor: null,
        brandId: null,
        curvesQty: 3
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      // if(row) {
      //   this.getPowerData(row);
      // }

      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = 'Create Voltage Curves';
      // this.myChart = [null,null,null]
     setTimeout(() => {
       // this.renderChart(0)
       // this.renderChart(1)
       // this.renderChart(2)
      },1000)
    },
    details(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.curvesId : ids
      getSkuCurves({"id":id}).then(response => {
        this.form = response.data
        response.data.curvesRecordList.forEach(i => {
          if(i.level === 0) {
            this.curvesListDataArray[0].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          } else if(i.level === 1) {
            this.curvesListDataArray[1].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          } else if(i.level === 2) {
            this.curvesListDataArray[2].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
          }
        })
        this.formTitle = 'Details'
        this.open = true
      })
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {

      this.reset()
      this.formType = 2
      const id = row ? row.curvesId : ids
      getSkuCurves({"id":id}).then(response => {
        this.form = response.data
        this.getPowerData(this.form.skuId,response.data.curvesRecordList);
        this.changeBrandProduct(this.form.skuId);
        // response.data.curvesRecordList.forEach(i => {
        //   if(i.level === 0) {
        //     this.curvesListDataArray[0].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
        //   } else if(i.level === 1) {
        //     this.curvesListDataArray[1].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
        //   } else if(i.level === 2) {
        //     this.curvesListDataArray[2].series[0].data = [i.one,i.two,i.three,i.four,i.five,i.six,i.seven,i.eight]
        //   }
        // })
        this.formTitle = 'Modify Voltage Curves'
        this.open = true
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form.curvesRecordList);
      // return;
      if (this.form.id !== undefined && this.form.id !== null) {
        console.log('进来了;;;')
        // this.form.curvesRecordList = [];
        let curvesRecordList = [];
        if(this.form.curvesQty == 1) {
          let chartData = this.curvesListDataArray[0].series[0].data;
          console.log('1')
          let newCurvesRecord = this.form.curvesRecordList.filter(i => i.level === 0);
          console.log(newCurvesRecord);
          // return;
          let itemData = {
            name: this.form.name,
            type: 0,
            id:newCurvesRecord.length > 0 ? newCurvesRecord[0].id : null,
            curvesId:this.form.id,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7],
          }
          curvesRecordList = [itemData]
        } else if(this.form.curvesQty == 2) {
          console.log('2')
          console.log(this.form.curvesRecordList)
          let newCurvesRecord = this.form.curvesRecordList.filter(i => i.level === 0);
          let newCurvesRecordTwo = this.form.curvesRecordList.filter(i => i.level === 1);
          console.log(newCurvesRecord)
          console.log(newCurvesRecordTwo);
          // return;
          let chartData = this.curvesListDataArray[0].series[0].data;
          let chartDataTwo = this.curvesListDataArray[1].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            id:newCurvesRecord.length > 0 ? newCurvesRecord[0].id : null,
            curvesId:this.form.id,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7]
          }
          let itemDataTwo = {
            name: this.form.name,
            type: 0,
            level: 1,
            id:newCurvesRecordTwo.length > 0 ? newCurvesRecordTwo[0].id : null,
            curvesId:this.form.id,
            one: chartDataTwo[0],
            two: chartDataTwo[1],
            three: chartDataTwo[2],
            four: chartDataTwo[3],
            five: chartDataTwo[4],
            six: chartDataTwo[5],
            seven: chartDataTwo[6],
            eight: chartDataTwo[7]
          }

          curvesRecordList = [itemData, itemDataTwo]
        }else if(this.form.curvesQty == 3) {
          console.log('3')
          let newCurvesRecord = this.form.curvesRecordList.filter(i => i.level === 0);
          let newCurvesRecordTwo = this.form.curvesRecordList.filter(i => i.level === 1);
          let newCurvesRecordThree = this.form.curvesRecordList.filter(i => i.level === 2);
          console.log(newCurvesRecord)
          console.log(newCurvesRecordTwo)
          console.log(newCurvesRecordThree)
          let chartData = this.curvesListDataArray[0].series[0].data;
          let chartDataTwo = this.curvesListDataArray[1].series[0].data;
          let chartDataThree = this.curvesListDataArray[2].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            level: 0,
            id:newCurvesRecord.length > 0 ? newCurvesRecord[0].id : null,
            curvesId:this.form.id,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7]
          }
          let itemDataTwo = {
            name: this.form.name,
            type: 0,
            level: 1,
            id:newCurvesRecordTwo.length > 0 ? newCurvesRecordTwo[0].id : null,
            curvesId:this.form.id,
            one: chartDataTwo[0],
            two: chartDataTwo[1],
            three: chartDataTwo[2],
            four: chartDataTwo[3],
            five: chartDataTwo[4],
            six: chartDataTwo[5],
            seven: chartDataTwo[6],
            eight: chartDataTwo[7]
          }

          let itemDataThree = {
            name: this.form.name,
            type: 0,
            level: 2,
            id:newCurvesRecordThree.length > 0 ? newCurvesRecordThree[0].id : null,
            curvesId:this.form.id,
            one: chartDataThree[0],
            two: chartDataThree[1],
            three: chartDataThree[2],
            four: chartDataThree[3],
            five: chartDataThree[4],
            six: chartDataThree[5],
            seven: chartDataThree[6],
            eight: chartDataThree[7]
          }

          curvesRecordList = [itemData, itemDataTwo,itemDataThree]
        }
        this.form.curvesRecordList = curvesRecordList;
      } else {
        this.form.curvesRecordList = [];
        let curvesRecordList = [];
        console.log(this.form.curvesQty)
        if(this.form.curvesQty == 1) {
          let chartData = this.curvesListDataArray[0].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7],
          }
          curvesRecordList = [itemData]
        } else if(this.form.curvesQty == 2) {
          let chartData = this.curvesListDataArray[0].series[0].data;
          let chartDataTwo = this.curvesListDataArray[1].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7]
          }
          let itemDataTwo = {
            name: this.form.name,
            type: 0,
            level: 1,
            one: chartDataTwo[0],
            two: chartDataTwo[1],
            three: chartDataTwo[2],
            four: chartDataTwo[3],
            five: chartDataTwo[4],
            six: chartDataTwo[5],
            seven: chartDataTwo[6],
            eight: chartDataTwo[7]
          }

          curvesRecordList = [itemData, itemDataTwo]
        } else if(this.form.curvesQty == 3) {
          console.log(this.curvesListDataArray)
          let chartData = this.curvesListDataArray[0].series[0].data;
          let chartDataTwo = this.curvesListDataArray[1].series[0].data;
          let chartDataThree = this.curvesListDataArray[2].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7]
          }
          let itemDataTwo = {
            name: this.form.name,
            type: 0,
            level: 1,
            one: chartDataTwo[0],
            two: chartDataTwo[1],
            three: chartDataTwo[2],
            four: chartDataTwo[3],
            five: chartDataTwo[4],
            six: chartDataTwo[5],
            seven: chartDataTwo[6],
            eight: chartDataTwo[7]
          }

          let itemDataThree = {
            name: this.form.name,
            type: 0,
            level: 2,
            one: chartDataThree[0],
            two: chartDataThree[1],
            three: chartDataThree[2],
            four: chartDataThree[3],
            five: chartDataThree[4],
            six: chartDataThree[5],
            seven: chartDataThree[6],
            eight: chartDataThree[7]
          }

          curvesRecordList = [itemData, itemDataTwo,itemDataThree]
        }else {
          let chartData = this.curvesListDataArray[0].series[0].data;
          let chartDataTwo = this.curvesListDataArray[1].series[0].data;
          let chartDataThree = this.curvesListDataArray[2].series[0].data;
          let itemData = {
            name: this.form.name,
            type: 0,
            level: 0,
            one: chartData[0],
            two: chartData[1],
            three: chartData[2],
            four: chartData[3],
            five: chartData[4],
            six: chartData[5],
            seven: chartData[6],
            eight: chartData[7]
          }
          let itemDataTwo = {
            name: this.form.name,
            type: 0,
            level: 1,
            one: chartDataTwo[0],
            two: chartDataTwo[1],
            three: chartDataTwo[2],
            four: chartDataTwo[3],
            five: chartDataTwo[4],
            six: chartDataTwo[5],
            seven: chartDataTwo[6],
            eight: chartDataTwo[7]
          }

          let itemDataThree = {
            name: this.form.name,
            type: 0,
            level: 2,
            one: chartDataThree[0],
            two: chartDataThree[1],
            three: chartDataThree[2],
            four: chartDataThree[3],
            five: chartDataThree[4],
            six: chartDataThree[5],
            seven: chartDataThree[6],
            eight: chartDataThree[7]
          }

          curvesRecordList = [itemData, itemDataTwo,itemDataThree]
        }
        this.form.curvesRecordList = curvesRecordList;
      }

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateSkuCurves(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addSkuCurves(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
